import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Pagination from "react-js-pagination";

import { getSearchedArticles } from "../services/article.service";

import Loading from "../components/loading.component";

const ArticleSearch = () => {
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  const [articles, setArticles] = useState([]);

  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    setLoading(true);
    setArticles([]);
    setTotalItems(0);

    if (
      searchParams.get("keyword") ||
      searchParams.get("tagged") ||
      searchParams.get("country")
    ) {
      getSearchedArticles({
        page: currentPage - 1,
        ...(searchParams.get("keyword") && {
          keyword: searchParams.get("keyword"),
        }),
        ...(searchParams.get("tagged") && {
          tagged: searchParams.get("tagged"),
        }),
        ...(searchParams.get("country") && {
          country: searchParams.get("country"),
        }),
      }).then((res) => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setArticles(res.data.articles);
        setTotalItems(res.data.totalItems);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [currentPage, searchParams]);

  return loading ? (
    <Loading />
  ) : (
    <div className="min-vh-100" style={{ marginTop: 120 }}>
      <div className="container">
        <h2 className="mb-4" style={{ fontWeight: 600 }}>
          {searchParams.get("keyword")
            ? `Articles that contain: ${searchParams.get("keyword")}`
            : searchParams.get("tagged")
            ? `Articles tagged: ${searchParams.get("tagged")}`
            : searchParams.get("country") &&
              `Articles in country: ${searchParams.get("country")}`}
        </h2>
        {articles.length > 0 ? (
          <>
            <ul className="search-articles">
              {articles.map((d) => (
                <li key={d._id} className="row my-3">
                  <div className="col-lg-2 col-md-auto img-wrapper">
                    <Link to={`/article/${d.slug}`}>
                      <img src={d.src} alt={d.slug} />
                    </Link>
                  </div>
                  <div className="col-lg-10 col-md-auto details">
                    <h5>
                      <Link to={`/article/${d.slug}`}>{d.title}</Link>
                    </h5>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: d.meta_desc.substring(0, 200) + "...",
                      }}
                    />
                  </div>
                </li>
              ))}
            </ul>
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={5}
              totalItemsCount={totalItems}
              pageRangeDisplayed={5}
              itemClass="page-item"
              linkClass="page-link"
              prevPageText="‹"
              nextPageText="›"
              onChange={(e) => setCurrentPage(e)}
            />
          </>
        ) : (
          <h5 className="text-start">No articles were found</h5>
        )}
      </div>
    </div>
  );
};

export default ArticleSearch;
