import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import ReactGA from "react-ga4";

import Navbar from "../components/navbar.component";
import Home from "../pages/home.page";
import Article from "../pages/article.page";
import ArticleSearch from "../pages/search.page";
import Login from "../pages/login.page";
import Discover from "../pages/discover.page";
import Dashboard from "../pages/dashboard.page";
import Footer from "../components/footer.component";

import Loading from "../components/loading.component";

import { setCountries, setCurrentUser, setLikes } from "../redux/actions";
import { getCountries } from "../services/country.service";

const Routing = () => {
  ReactGA.initialize("G-603BL9FZB6");

  const location = useLocation();
  const dispatch = useDispatch();
  const { currentUser, likes } = useSelector((state) => state);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      if (user && user.accessToken) {
        dispatch(setCurrentUser(user));
      }
      await getCountries()
        .then((res) => {
          dispatch(setCountries(res.data));
          setLoading(false);
        })
        .catch(() => setLoading(false));
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (likes !== null) {
      dispatch(setLikes(JSON.parse(localStorage.getItem("likes"))));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    currentUser && localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  useEffect(() => {
    localStorage.setItem("likes", JSON.stringify(likes));
    // eslint-disable-next-line
  }, [likes]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  useEffect(() => {
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    };

    if (currentUser) {
      const decodedJwt = parseJwt(currentUser.accessToken);

      if (decodedJwt.exp * 1000 < Date.now()) {
        dispatch(setCurrentUser(undefined));
        localStorage.removeItem("user");
      }
    }
    // eslint-disable-next-line
  }, [currentUser, location]);

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        closeButton={false}
        hideProgressBar
        newestOnTop
        closeOnClick
        theme="dark"
        toastClassName="toastify"
        rtl={false}
        draggable
        pauseOnHover
      />
      <Navbar />
      {loading ? (
        <Loading />
      ) : (
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="*" element={<Navigate to="/" />} />
          <Route exact path="/discover" element={<Discover />} />
          <Route exact path="/article/:slugParam" element={<Article />} />
          <Route exact path="/search" element={<ArticleSearch />} />
          <Route
            exact
            path="/admin"
            element={!currentUser ? <Login /> : <Navigate to="/dashboard" />}
          />
          <Route
            exact
            path="/dashboard"
            element={currentUser ? <Dashboard /> : <Navigate to="/admin" />}
          />
        </Routes>
      )}
      <Footer />
    </>
  );
};

export default Routing;
