import { useEffect, useState } from "react";
import {
  PencilSquareIcon,
  PlusCircleIcon,
  StarIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { StarIcon as StarIconFilled } from "@heroicons/react/24/solid";
import { WithContext as ReactTags } from "react-tag-input";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import RichTextEditor from "react-rte";
import Pagination from "react-js-pagination";

import useIsDesktop from "../hooks/useIsDesktop";

import {
  beginArticleUpdate,
  cancelArticleUpdate,
  createArticle,
  deleteArticle,
  getArticles,
  getTags,
  updateArticle,
  updateAuthorPick,
} from "../services/article.service";

import {
  createCountry,
  deleteCountry,
  updateCountry,
} from "../services/country.service";

import Confirmation from "../components/confirmation.component";

import Loading from "../components/loading.component";

import { setCountries, setCurrentUser } from "../redux/actions";

const Dashboard = () => {
  const dispatch = useDispatch();

  const isDesktop = useIsDesktop();

  const { countries, currentUser } = useSelector((state) => state);

  const [articles, setArticles] = useState([]);
  const [editArticle, setEditArticle] = useState(undefined);
  const [currentTags, setCurrentTags] = useState([]);

  const delimiters = [188, 13];

  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    getTags()
      .then((res) => {
        setCurrentTags(res.data);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);
    getArticles({ page: currentPage - 1 }).then((res) => {
      setArticles(res.data.articles);
      setTotalItems(res.data.totalItems);
      setLoading(false);
    });
  }, [currentPage]);

  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [content, setContent] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [imgSrc, setImgSrc] = useState("");
  const [chosenCountry, setChosenCountry] = useState("");
  const [newCountry, setNewCountry] = useState("");
  const [tags, setTags] = useState([]);

  const [countryName, setCountryName] = useState("");

  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createValueFromString(content, "html")
  );

  const handleContentChange = (value) => {
    setEditorValue(value);
    setContent(value.toString("html"));
  };

  const handleTagsAddition = (tag) => {
    tags.length < 5 && setTags([...tags, tag]);
  };

  const handleTagsDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddCountry = () => {
    if (newCountry.trim() === "") return;

    const countryExists = countries.some(
      (c) => c.name.toLowerCase() === newCountry.toLowerCase()
    );
    if (!countryExists) {
      const newCountryObj = { _id: Date.now().toString(), name: newCountry };
      setCountries([...countries, newCountryObj]);
      setChosenCountry(newCountryObj);
      setNewCountry("");
    } else {
      alert("Country already exists!");
    }
  };

  const handlePostArticle = () => {
    if (
      title !== "" &&
      slug !== "" &&
      content !== "" &&
      metaDesc !== "" &&
      chosenCountry !== "" &&
      imgSrc !== ""
    ) {
      setDisabled(true);

      const data = {
        title: title,
        slug: slug,
        content: content,
        meta_desc: metaDesc,
        country: chosenCountry.name,
        tags: tags,
        author: currentUser.name,
        src: imgSrc,
        likes: 0,
        readTime: Math.ceil(content.trim().split(/\s+/).length / 250),
        isEditMode: false,
      };

      createArticle(data)
        .then(async () => {
          resetFields();
          window.location.reload();
        })
        .catch((err) => {
          toast(err.response.data.error);
          setDisabled(false);
        });
    } else {
      toast("All fields are required");
    }
  };

  const handleBeginArticleUpdate = async (id) => {
    await beginArticleUpdate(id)
      .then((res) => {
        setEditArticle(res.data.result);
      })
      .catch((err) => {
        toast(err.response.data.error);
        setEditArticle(undefined);
      });
  };

  useEffect(() => {
    if (editArticle) {
      setTitle(editArticle.title);
      setSlug(editArticle.slug);
      setEditorValue(
        RichTextEditor.createValueFromString(editArticle.content, "html")
      );
      setContent(editArticle.content);
      setMetaDesc(editArticle.meta_desc);
      setImgSrc(editArticle.src);
      setChosenCountry(editArticle.country);
      setTags(editArticle.tags);
    }
  }, [editArticle]);

  const handleCancelArticleUpdate = async (id) => {
    await cancelArticleUpdate(id).then(() => {
      setEditArticle(undefined);
      resetFields();
    });
  };

  const handleDeleteArticle = async (id) => {
    await deleteArticle(id)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        toast(err.response.data.error);
      });
  };

  const handleUpdateArticle = async () => {
    if (
      title !== "" &&
      slug !== "" &&
      content !== "" &&
      metaDesc !== "" &&
      chosenCountry !== "" &&
      imgSrc !== ""
    ) {
      setDisabled(true);

      const data = {
        _id: editArticle._id,
        title: title,
        slug: slug,
        content: content,
        meta_desc: metaDesc,
        country: chosenCountry.name,
        tags: tags,
        src: imgSrc,
        likes: 0,
        readTime: Math.ceil(content.trim().split(/\s+/).length / 250),
        isEditMode: false,
      };
      await updateArticle(data)
        .then(() => {
          resetFields();
          setDisabled(false);
          window.location.reload();
        })
        .catch((err) => {
          toast(err.response.data.error);
          setDisabled(false);
        });
    } else {
      toast("All fields are required");
    }
  };

  const handleUpdateAuthorPick = async (articleId) => {
    await updateAuthorPick(currentUser.id, articleId)
      .then((res) => {
        dispatch(
          setCurrentUser({
            ...currentUser,
            article_pick: res.data.result.article_pick,
          })
        );
        toast(res.data.msg);
        window.location.reload();
      })
      .catch((err) => {
        toast(err.response.data.error);
      });
  };

  const handleCreateCountry = () => {
    if (countryName !== "") {
      setDisabled(true);

      const data = {
        name: countryName,
      };

      createCountry(data)
        .then(async () => {
          setCountryName("");
          window.location.reload();
        })
        .catch((err) => {
          toast(err.response.data.error);
          setDisabled(false);
        });
    } else {
      toast("All fields are required");
    }
  };

  const handleDeleteCountry = async (id) => {
    await deleteCountry(id)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        toast(err.response.data.error);
      });
  };

  const handleUpdateCountry = async (id) => {
    await updateCountry(
      id,
      countries.find((c) => c._id === id)
    )
      .then((res) => toast(res.data.msg))
      .catch((err) => toast(err.response.data.error));
  };

  const preventRefresh = () => {
    if (editArticle) {
      handleCancelArticleUpdate(editArticle._id);
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", preventRefresh);

    return () => {
      window.removeEventListener("beforeunload", preventRefresh);
    };
    // eslint-disable-next-line
  }, [editArticle]);

  const resetFields = () => {
    setTitle("");
    setSlug("");
    setContent("");
    setEditorValue(RichTextEditor.createEmptyValue);
    setMetaDesc("");
    setImgSrc("");
    setChosenCountry("");
    setTags([]);
    setDisabled(false);
  };

  const submitIfPressedEnter = (e) =>
    e.key === "Enter" &&
    (!editArticle ? handlePostArticle : handleUpdateArticle);

  return loading ? (
    <Loading />
  ) : (
    <div className="min-vh-100 dashboard" style={{ marginTop: 120 }}>
      <div className="container">
        <div className="hstack mb-4">
          <h1>All Articles</h1>
          <button
            type="button"
            className="btn btn-primary ms-auto"
            data-bs-toggle="modal"
            data-bs-target="#newArticleModal"
          >
            <PlusCircleIcon />
          </button>
        </div>
        <ul className="current-articles">
          {articles.map((d) => (
            <li key={d._id} className="row my-3">
              <div className="col-lg-2 col-md-auto img-wrapper">
                <Link to={`/article/${d.slug}`}>
                  <img src={d.src} alt={d.slug} />
                </Link>
              </div>
              <div className="col-lg-8 col-md-auto details">
                <h5>
                  <Link to={`/article/${d.slug}`}>{d.title}</Link>
                </h5>
                <p
                  dangerouslySetInnerHTML={{
                    __html: d.meta_desc.substring(0, 200) + "...",
                  }}
                />
              </div>
              {isDesktop ? (
                <>
                  <div className="col-1">
                    <h5 className="likes">{d.likes} Likes</h5>
                  </div>
                  <div className="col-1 d-flex gap-2 justify-content-end mb-auto">
                    {d._id === currentUser.article_pick ? (
                      <button onClick={() => handleUpdateAuthorPick("")}>
                        <StarIconFilled className="filled" />
                      </button>
                    ) : (
                      <button onClick={() => handleUpdateAuthorPick(d._id)}>
                        <StarIcon />
                      </button>
                    )}

                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#newArticleModal"
                      onClick={() => {
                        handleBeginArticleUpdate(d._id);
                      }}
                    >
                      <PencilSquareIcon />
                    </button>
                    <button
                      data-bs-toggle="modal"
                      data-bs-target={`#delete-confirm-${d._id}`}
                    >
                      <TrashIcon />
                    </button>
                  </div>
                </>
              ) : (
                <div className="hstack justify-content-start gap-2">
                  <h5 className="likes mt-2">{d.likes} Likes</h5>
                  <div className="ms-auto hstack gap-2">
                    <button>
                      <StarIcon />
                    </button>
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#newArticleModal"
                      onClick={() => {
                        handleBeginArticleUpdate(d._id);
                      }}
                    >
                      <PencilSquareIcon />
                    </button>
                    <button
                      data-bs-toggle="modal"
                      data-bs-target={`#delete-confirm-${d._id}`}
                    >
                      <TrashIcon />
                    </button>
                  </div>
                </div>
              )}
              <Confirmation
                id={`delete-confirm-${d._id}`}
                content={`Are you sure you want to delete ${d.title}?`}
                handleConfirm={() => handleDeleteArticle(d._id)}
              />
            </li>
          ))}
        </ul>
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={5}
          totalItemsCount={totalItems}
          pageRangeDisplayed={5}
          itemClass="page-item"
          linkClass="page-link"
          prevPageText="‹"
          nextPageText="›"
          onChange={(e) => setCurrentPage(e)}
        />
        <div
          className="modal fade"
          id="newArticleModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="newArticleStaticModal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div className="modal-content new-article-modal">
              <div className="modal-header">
                <h5 className="modal-title" id="newArticleStaticModal">
                  {!editArticle
                    ? "Post a new article"
                    : `Updating article: ${editArticle.title}`}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={() =>
                    editArticle && handleCancelArticleUpdate(editArticle._id)
                  }
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="newArticleTitle">Title</label>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    onKeyDown={submitIfPressedEnter}
                    className="form-control shadow-none"
                    id="newArticleTitle"
                    placeholder="Give it a catchy title"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="newArticleSlug">Slug</label>
                  <input
                    type="text"
                    value={slug}
                    onChange={(e) => {
                      const regex = /^[a-zA-Z0-9-]*$/;
                      const newValue = e.target.value;
                      if (regex.test(newValue)) {
                        setSlug(newValue);
                      }
                    }}
                    onKeyDown={submitIfPressedEnter}
                    className="form-control shadow-none"
                    id="newArticleSlug"
                    placeholder="Article slug"
                  />
                </div>
                <div className="form-group w-100">
                  <label htmlFor="newArticleContent">Content</label>
                  <div className="rte-wrapper">
                    <RichTextEditor
                      value={editorValue}
                      onChange={handleContentChange}
                      onKeyDown={submitIfPressedEnter}
                      toolbarConfig={{
                        display: [
                          "INLINE_STYLE_BUTTONS",
                          "BLOCK_TYPE_BUTTONS",
                          "LINK_BUTTONS",
                          "IMAGE_BUTTON",
                          "BLOCK_TYPE_DROPDOWN",
                          "HISTORY_BUTTONS",
                        ],
                        INLINE_STYLE_BUTTONS: [
                          {
                            label: "Bold",
                            style: "BOLD",
                            className: "custom-css-className",
                          },
                          { label: "Italic", style: "ITALIC" },
                          { label: "Underline", style: "UNDERLINE" },
                        ],
                        BLOCK_TYPE_DROPDOWN: [
                          { label: "Normal", style: "unstyled" },
                          { label: "Heading Large", style: "header-one" },
                          { label: "Heading Medium", style: "header-two" },
                          { label: "Heading Small", style: "header-three" },
                        ],
                        BLOCK_TYPE_BUTTONS: [
                          { label: "UL", style: "unordered-list-item" },
                          { label: "OL", style: "ordered-list-item" },
                        ],
                      }}
                      className="form-control"
                      toolbarClassName="rte-toolbar"
                      editorClassName="rte-editor"
                      editorStyle={{ minHeight: 180 }}
                      required={true}
                      type="string"
                      multiline={true}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="newArticleMetaDesc">Meta Description</label>
                  <textarea
                    type="text"
                    value={metaDesc}
                    onChange={(e) => setMetaDesc(e.target.value)}
                    onKeyDown={submitIfPressedEnter}
                    className="form-control shadow-none"
                    id="newArticleMetaDesc"
                    placeholder="Article meta description"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="newArticleImgSrc">Main image</label>
                  <input
                    type="text"
                    value={imgSrc}
                    onChange={(e) => setImgSrc(e.target.value)}
                    onKeyDown={submitIfPressedEnter}
                    className="form-control shadow-none"
                    id="newArticleImgSrc"
                    placeholder="Provide a valid image url"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="newArticleContent">Choose country</label>
                  <div className="nav-item dropdown">
                    <button
                      className="nav-link dropdown-toggle form-control"
                      style={{ width: "fit-content" }}
                      type="button"
                      id="newArticleCountriesDropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {chosenCountry.name || "Countries"}
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="newArticleCountriesDropdown"
                      style={{ boxShadow: "none" }}
                    >
                      {countries.map((c) => (
                        <li
                          key={c._id}
                          className="dropdown-item"
                          onClick={() => setChosenCountry(c)}
                        >
                          {c.name}
                        </li>
                      ))}
                      <li className="dropdown-item">
                        <input
                          type="text"
                          value={newCountry}
                          onChange={(e) => setNewCountry(e.target.value)}
                          className="form-control shadow-none"
                          placeholder="Add new country"
                        />
                        <button
                          type="button"
                          className="btn btn-primary mt-2"
                          onClick={handleAddCountry}
                        >
                          Add Country
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="newArticleContent">Enter tags</label>
                  <ReactTags
                    tags={tags}
                    suggestions={currentTags}
                    delimiters={delimiters}
                    handleDelete={handleTagsDelete}
                    handleAddition={handleTagsAddition}
                    allowDragDrop={false}
                    placeholder="Add tags related to the article"
                    className="form-control"
                    inputFieldPosition="top"
                    autocomplete={true}
                  />
                </div>
              </div>
              <div className="modal-footer gap-2">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() =>
                    editArticle && handleCancelArticleUpdate(editArticle._id)
                  }
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={
                    !editArticle ? handlePostArticle : handleUpdateArticle
                  }
                  disabled={disabled}
                >
                  {!editArticle ? "Post It" : "Update It"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="hstack my-4">
          <h1>All Countries</h1>
          <div className="d-flex ms-auto gap-4">
            <button
              type="button"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#newCountryModal"
            >
              <PlusCircleIcon />
            </button>
          </div>
        </div>
        <ul className="current-countries">
          {countries.map((d) => (
            <li key={d._id}>
              <input
                type="text"
                value={d.name}
                onChange={(e) => {
                  dispatch(
                    setCountries(
                      countries.map((cat) =>
                        cat._id === d._id ? { ...d, name: e.target.value } : cat
                      )
                    )
                  );
                }}
              />
              <button
                onClick={() => {
                  handleUpdateCountry(d._id);
                }}
              >
                <PencilSquareIcon />
              </button>
              <button
                data-bs-toggle="modal"
                data-bs-target={`#delete-confirm-country-${d._id}`}
              >
                <TrashIcon />
              </button>
              <Confirmation
                id={`delete-confirm-country-${d._id}`}
                content={`Are you sure you want to delete ${d.name}?`}
                handleConfirm={() => handleDeleteCountry(d._id)}
              />
            </li>
          ))}
        </ul>
        <div
          className="modal fade"
          id="newCountryModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="newCountryStaticModal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
            <div className="modal-content new-article-modal">
              <div className="modal-header">
                <h5 className="modal-title" id="newArticleStaticModal">
                  Add new country
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="newCountryName">Name</label>
                  <input
                    type="text"
                    value={countryName}
                    onChange={(e) => setCountryName(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && handleCreateCountry}
                    className="form-control shadow-none"
                    id="newCountryName"
                    placeholder="Country name"
                  />
                </div>
              </div>
              <div className="modal-footer gap-2">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleCreateCountry}
                  disabled={disabled}
                >
                  Create It
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
