export const setTheme = (payload) => {
  return {
    type: "SET_COLOR_THEME",
    payload: payload,
  };
};

export const setCurrentUser = (payload) => {
  return {
    type: "SET_CURRENT_USER",
    payload: payload,
  };
};

export const setCountries = (payload) => {
  return {
    type: "SET_CATEGORIES",
    payload: payload,
  };
};

export const setLikes = (payload) => {
  return {
    type: "SET_LIKES",
    payload: payload,
  };
};
