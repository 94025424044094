import http from "../common/http-common";
import authHeader from "./auth.service";

export const createCountry = async (data) => {
  return http.post("/country/create", data, {
    headers: authHeader(),
  });
};

export const getCountries = async () => {
  return http.get("/country/get");
};

export const updateCountry = async (id, data) => {
  return http.put(`/country/update/${id}`, data, {
    headers: authHeader(),
  });
};

export const deleteCountry = async (id) => {
  return http.delete(`/country/delete/${id}`, {
    headers: authHeader(),
  });
};
