const initialState = {
  colorTheme: "light",
  currentUser: undefined,
  countries: [],
  likes: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_COLOR_THEME":
      return { ...state, colorTheme: payload };
    case "SET_CURRENT_USER":
      return { ...state, currentUser: payload };
    case "SET_CATEGORIES":
      return { ...state, countries: payload };
    case "SET_LIKES":
      return { ...state, likes: payload };
    default:
      return state;
  }
};

export default reducer;
